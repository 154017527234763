import { getBackgroundCSS, getSpaceCSS, getBoxCSS, getColorsCSS, getTypoCSS } from '../../../../Components/utils/getCSS';

const Style = ({ attributes, clientId }) => {
	const { width = {}, height, alignment = 'center', background, padding, pageSize, pageColor, prevNextSize, prevNextColor, image = { width: '100%' }, caption = {} } = attributes;
	const { desktop: dHeight, tablet: tHeight, mobile: mHeight } = height;

	const { verticalAlign = 'bottom', typo = { fontSize: { desktop: 16, tablet: 15, mobile: 15 } }, textAlign = 'left', colors = { color: '#fff', bg: '#0000' }, overlay = { type: 'gradient', gradient: 'linear-gradient(0deg, #000 0%, #0000 50%, #0000 100%)' }, padding: capPadding = { top: '5px', right: '8px', bottom: '5px', left: '8px' } } = caption;

	const mainSl = `#bicbCarousel-${clientId}`;
	const carouselSl = `${mainSl} .bicbCarousel`;
	const slideSl = `${carouselSl} .swiper-wrapper .swiper-slide`;
	const captionSl = `${slideSl} .caption`;

	const tabWidth = width?.tablet || width?.desktop;
	const mobWidth = width?.mobile || tabWidth;

	const desHeight = dHeight || height;
	const tabHeight = tHeight || desHeight;
	const mobHeight = mHeight || tabHeight;

	return <>
		<style dangerouslySetInnerHTML={{
			__html: `
			${getTypoCSS('', typo).googleFontLink}
			${getTypoCSS(`${captionSl} p`, typo).styles}

			${mainSl}{
				text-align: ${alignment};
				${getBackgroundCSS(background)}
			}
			${carouselSl}{
				width: ${width?.desktop};
				height: ${desHeight};
				padding: ${getSpaceCSS(padding)};
			}
			${carouselSl} .swiper-pagination .swiper-pagination-bullet{
				background: ${pageColor};
				width: ${pageSize};
				height: ${pageSize};
			}
			${carouselSl} .swiper-button-prev,
			${carouselSl} .swiper-button-next{
				color: ${prevNextColor};
			}
			${carouselSl} .swiper-button-prev::after,
			${carouselSl} .swiper-button-next::after {
				font-size: ${prevNextSize};
			}
			${slideSl} .carousel img{
				width: ${0 === parseInt(image?.width) ? 'auto' : image?.width}
			}
			${captionSl} {
				justify-content: ${'right' === textAlign ? 'end' : 'left' === textAlign ? 'start' : 'center'};
			}
			${captionSl}.onImage {
				align-items: ${'bottom' === verticalAlign ? 'end' : 'top' === verticalAlign ? 'start' : 'center'};
				${getBackgroundCSS(overlay)};
			}
			${captionSl} p {
				text-align: ${textAlign};
				${getColorsCSS(colors)};
				padding: ${getBoxCSS(capPadding)};
			}

			@media (max-width: 768px) {
				${carouselSl}{
					width: ${tabWidth};
					height: ${tabHeight};
				}
			}

			@media (max-width: 576px) {
				${carouselSl}{
					width: ${mobWidth};
					height: ${mobHeight};
				}
			}
			`.replace(/\s+/g, ' ')
		}} />
	</>
}
export default Style;