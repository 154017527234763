import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination, EffectFade, EffectCube, EffectCreative, EffectCoverflow, EffectFlip, EffectCards } from 'swiper/modules';
import { Fancybox } from '@fancyapps/ui';

import Slide from './Slide';

const Carousel = ({ attributes, clientId, initialSlide = 0, isBackend = false }) => {
	const { carousels, elements = { linkNewTab: false, linkOn: 'image' }, carouselOptions, isPage, isPrevNext, caption = {} } = attributes;
	const { columns, columnGap, isLoop, allowTouchMove = true, speed, autoplayOptions, freeMode = {}, effect = 'slide', mousewheel = {}, pagination = {} } = carouselOptions;
	const { enabled: isAutoplay, delay = 1.5 } = autoplayOptions;
	const { position: capPos = 'onImage' } = caption;

	Fancybox.bind(`[data-fancybox='bicbCarouselGallery-${clientId}']`, {
		wheel: 'zoom',
		autoFocus: false,
		backdropClick: 'close',
		closeButton: 'auto',
		commonCaption: false,
		contentClick: 'toggleZoom', // 'toggleZoom' | 'toggleCover' | 'toggleMax' | 'zoomToFit' | 'zoomToMax' | 'iterateZoom' | false | 'close' | 'next' | 'prev';
		contentDblClick: 'toggleCover', // 'toggleZoom' | 'toggleCover' | 'toggleMax' | 'zoomToFit' | 'zoomToMax' | 'iterateZoom' | false | 'close' | 'next' | 'prev';
		Carousel: {
			transition: 'fade'
		},
		Thumbs: {
			type: 'classic',
			showOnStart: true,
			minCount: 2,
		}
	});

	return <Swiper
		className={`bicbCarousel ${'bellowImage' === capPos ? 'bellowImageCaption' : ''}`}
		direction='horizontal'
		initialSlide={initialSlide}
		slidesPerView={columns.mobile}
		breakpoints={{ 576: { slidesPerView: columns.tablet }, 768: { slidesPerView: columns.desktop } }}
		spaceBetween={columnGap}
		modules={[Autoplay, Navigation, Pagination, EffectFade, EffectCube, EffectCreative, EffectCoverflow, EffectFlip, EffectCards]}
		loop={isLoop}
		allowTouchMove={isBackend ? false : allowTouchMove}
		grabCursor={isBackend ? false : allowTouchMove}
		speed={speed * 1000}
		autoplay={isAutoplay && !isBackend ? { ...autoplayOptions, delay: delay * 1000 } : false}
		freeMode={freeMode}
		effect={effect}
		fadeEffect={{ crossFade: false }}
		cubeEffect={{ shadow: false }}
		creativeEffect={{
			prev: {
				shadow: true,
				translate: ['-120%', 0, -500],
			},
			next: {
				shadow: true,
				translate: ['120%', 0, -500],
			}
		}}
		cardsEffect={{ slideShadows: false }}
		mousewheel={mousewheel}
		pagination={isPage ? {
			clickable: true,
			dynamicBullets: false,
			forceClass: true
		} : false}
		navigation={isPrevNext ? { clickable: pagination.clickable, dynamicBullets: pagination.dynamicBullets } : false}
		allowSlidePrev={true}
		allowSlideNext={true}
		autoHeight={false}
		notificationClass={null}
	>
		{carousels?.map((carousel, index) => {
			const { action = 'none', link = '' } = carousel;

			const slideProps = { attributes, carousel, index, clientId };

			return <SwiperSlide key={index}>
				{/* <Slide attributes={attributes} carousel={carousel} index={index} clientId={clientId} /> */}

				{'link' === action && link && 'image' === elements?.linkOn && 'onImage' === capPos ? <a href={link} target={elements?.linkNewTab ? '_blank' : '_self'} rel='noreferrer'>
					<Slide {...slideProps} />
				</a> : <Slide {...slideProps} />}
			</SwiperSlide>
		})}
	</Swiper>
}
export default Carousel;