const Slide = ({ attributes, carousel, index, clientId }) => {
	const { elements = { linkNewTab: false, linkOn: 'image' }, caption: attrCap = {} } = attributes;
	const { position: capPos = 'onImage' } = attrCap;
	const { image, action = 'none', link = '' } = carousel;

	const imgProps = { carousel, action, image, clientId };
	const linkProps = { linkNewTab: elements?.linkNewTab, action, link };

	return <>
		<figure className={`carousel carousel-${index}`}>
			{'image' === elements?.linkOn && 'bellowImage' === capPos ? <LinkEl {...linkProps}>
				<ImageEl {...imgProps} />
			</LinkEl> : <ImageEl {...imgProps} />}
		</figure>

		<CaptionEl attributes={attributes} carousel={carousel} />
	</>
}
export default Slide;

const ImageEl = ({ carousel, clientId }) => {
	const { image, action = 'none', caption = '' } = carousel;
	const { url = '', alt = '', title = '' } = image || {};

	return url && 'lightbox' === action ? <a data-fancybox={`bicbCarouselGallery-${clientId}`} data-caption={caption} className='fancybox' href={url}>
		<img src={url} alt={alt || title} />
	</a> : <img src={url} alt={alt || title} />
}

const CaptionEl = ({ attributes, carousel }) => {
	const { elements = { linkNewTab: false, linkOn: 'image' }, caption } = attributes;
	const { display = 'none', position = 'onImage' } = caption || {};
	const { action = 'none', link = '', caption: capText = '' } = carousel;

	const linkProps = { linkNewTab: elements?.linkNewTab, action, link };

	return (capText && 'none' !== display) && <div className={`caption display-${display} ${position}`}>
		{'caption' === elements?.linkOn ? <LinkEl {...linkProps}>
			<p dangerouslySetInnerHTML={{ __html: capText }} />
		</LinkEl> : <p dangerouslySetInnerHTML={{ __html: capText }} />}
	</div>
}

const LinkEl = ({ linkNewTab, action = 'none', link, children }) => {
	return ('link' === action && link) ? <a href={link} target={linkNewTab ? '_blank' : '_self'} rel='noreferrer'>
		{children}
	</a> : children;
}